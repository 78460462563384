<div class="menu">
	<ion-grid>
		<ion-row>
			<ion-col>
				<ion-button
					class="button-transparent"
					fill="clear"
					[routerLink]="['/patient/home']"
					routerDirection="root"
					(click)="cambiarDisplay1()"
					><img alt="Icono de home" src="../../../assets/Menu_ic_Home.svg"
				/></ion-button>
			</ion-col>
			<ion-col>
				<ion-button
					class="button-transparent"
					fill="clear"
					[routerLink]="['/patient/biometric-graphs']"
					routerDirection="root"
					(click)="cambiarDisplay2()"
				><img alt="Icono de biometrics" src="../../../assets/Menu_ic_Biometric_graphs.svg"
				/></ion-button>
			</ion-col>
			<ion-col>
				<ion-button
					class="button-transparent"
					fill="clear"
					[routerLink]="['/patient/contents']"
					routerDirection="root"
					(click)="cambiarDisplay3()"
					><img alt="Icono de contents" src="../../../assets/Menu_ic_Esp terapeutico.svg"
				/></ion-button>
			</ion-col>
			<ion-col>
				<ion-button
					class="button-transparent"
					fill="clear"
					[routerLink]="['/patient/therapeutic-space']"
					routerDirection="root"
					(click)="cambiarDisplay4()"
					><img alt="Icono de therapeutic space" src="../../../assets/Menu_ic_Calendar.svg"
				/></ion-button>
			</ion-col>
			<ion-col>
				<ion-button
					class="button-transparent"
					fill="clear"
					[routerLink]="['/patient/profile']"
					routerDirection="root"
					(click)="cambiarDisplay5()"
					><img alt="Icono de profile" src="../../../assets/Menu_ic_Profile.svg"
				/></ion-button>
			</ion-col>
		</ion-row>
		<ion-row id="identificadores">
			<ion-col>
				<ion-icon id="Home" class="selector" src="../../../assets/Menu_ic_Selector.svg"></ion-icon>
			</ion-col>
			<ion-col>
				<ion-icon id="Biometrics" class="selector" src="../../../assets/Menu_ic_Selector.svg"></ion-icon>
			</ion-col>
			<ion-col>
				<ion-icon id="Contents" class="selector" src="../../../assets/Menu_ic_Selector.svg"></ion-icon>
			</ion-col>
			<ion-col>
				<ion-icon id="Space" class="selector" src="../../../assets/Menu_ic_Selector.svg"></ion-icon>
			</ion-col>
			<ion-col>
				<ion-icon id="Profile" class="selector" src="../../../assets/Menu_ic_Selector.svg"></ion-icon>
			</ion-col>
		</ion-row>
	</ion-grid>
</div>
